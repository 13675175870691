import { Injectable } from '@angular/core';
import { ApiService } from './api';

import {
  PostUserDeviceBody,
  PostUserDeviceResponse,
  PostUserFeedbackResponse,
} from '../../shared/interfaces/api/user-api.interface';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private apiService: ApiService) {}

  postUserDevice(body: PostUserDeviceBody): Promise<PostUserDeviceResponse> {
    return this.apiService.post(`/app/user/device`, body).toPromise();
  }

  postFeedback(rating: number, feedback: string): Promise<PostUserFeedbackResponse> {
    const body = { rating, feedback };

    return this.apiService.post(`/app/user/feedback`, body).toPromise();
  }

  getFlags(): Promise<Array<{ name: string; status: boolean }>> {
    return this.apiService.get(`/app/user/flags`).toPromise();
  }

  // Returns all user flags
  putFlag(flag: string, value: boolean): Promise<Array<{ name: string; status: boolean }>> {
    const body: {
      name: string;
      status: boolean | null;
    } = {
      name: flag,
      status: value,
    };

    return this.apiService.put(`/app/user/flag`, body).toPromise();
  }

  // This route returns []
  postRequestUserData(email: string, password: string): Promise<any> {
    const body = {
      email,
      password,
    };
    return this.apiService.post(`/app/user/data`, body).toPromise();
  }
}
