import { Injectable } from '@angular/core';
import { UserFlags } from '../../../shared/interfaces/user-flags.interface';
import { UserApiService } from '../../api/user-api.service';
import { DataProviderService } from '../data-provider/data-handler.service';
import { StorageService } from '../storage/storage.service';

type UserFlagNames =
  | 'view_results_tab_tooptip'
  | 'view_timeline_tab_tooltip'
  | 'view_profile_tab_tooltip'
  | 'view_challenge_onboarding'
  | 'dismissed_timeline_challenges_tip'
  | 'dismissed_timeline_articles_tip'
  | 'dismissed_timeline_questionnaire_tip'
  | 'timeline_completed_tab_tooltip'
  | 'results_biomarker_list_tip'
  | 'completed_onboarding'
  | 'app_rating_dismissed'
  | 'dismissed_timeline_journeys_tip'
  | 'weekly_plan_tooltip'
  | 'delete_account'
  | 'challenge_tooltip_difficulty'
  | 'challenge_tooltip_reminder'
  | 'challenge_tooltip_dismiss'
  | 'journey_tooltip_challenges'
  | 'challenge_tooltip_start'
  | 'activities_weekly_tooltip'
  | 'skip_waiting_questionnaires'
  | 'disable_analytics';

@Injectable({
  providedIn: 'root',
})
export class UserFlagService {
  private userFlags: UserFlags = null;

  constructor(
    private userApiService: UserApiService,
    private storageProvider: StorageService,
    private dataProvider: DataProviderService
  ) {
    this.updateFlags();
  }

  public getFlg(flag: string) {
    if (this.userFlags == null) {
      return false;
    }

    this.updateFlags();

    switch (flag) {
      case 'mainOnboarding':
        return this.userFlags.completed_onboarding || false;
      case 'resultsTabTooltip':
        return this.userFlags.view_results_tab_tooptip || false;
      case 'timelineTabTooltip':
        return this.userFlags.view_timeline_tab_tooltip || false;
      case 'profileTabTooltip':
        return this.userFlags.view_profile_tab_tooltip || false;
      case 'challengesOnboarding':
        return this.userFlags.view_challenge_onboarding || false;
      case 'timelineJourneysTip':
        return this.userFlags.dismissed_timeline_journeys_tip || false;
      case 'timelineArticlesTip':
        return this.userFlags.dismissed_timeline_articles_tip || false;
      case 'timelineQuestionsTip':
        return this.userFlags.dismissed_timeline_questionnaire_tip || false;
      case 'timelineCompletedTabTooltip':
        return this.userFlags.timeline_completed_tab_tooltip || false;
      case 'resultsBiomarkerList':
        return this.userFlags.results_biomarker_list_tip || false;
      case 'appRatingDismissed':
        return this.userFlags.app_rating_dismissed || false;
      case 'weeklyPlanTooltip':
        return this.userFlags.weekly_plan_tooltip || false;
      case 'disableAnalytics':
        return this.userFlags.disable_analytics || false;
      case 'deleteAccount':
        return this.userFlags.delete_account || false;
      default:
        return false;
    }
  }

  /* 0.8.5 Simplified method for maintability  */
  public getFlag(flag: UserFlagNames) {
    this.updateFlags();
    if (this.userFlags == null) {
      return false;
    }

    if (this.userFlags[flag]) {
      return this.userFlags[flag];
    }

    return false;
  }

  public setFlag(flag: UserFlagNames) {
    const flagToSync: any = {};

    this.userFlags[flag] = true;
    flagToSync[flag] = true;

    this.save();
    this.sync(flagToSync);
  }

  public resetFlagsLocally() {
    const flagsToReset = [
      'view_results_tab_tooptip',
      'view_timeline_tab_tooltip',
      'view_profile_tab_tooltip',
      'view_challenge_onboarding',
      'dismissed_timeline_challenges_tip',
      'dismissed_timeline_articles_tip',
      'dismissed_timeline_questionnaire_tip',
      'timeline_completed_tab_tooltip',
      'results_biomarker_list_tip',
      'dismissed_timeline_journeys_tip',
      'weekly_plan_tooltip',
      'challenge_tooltip_difficulty',
      'challenge_tooltip_reminder',
      'challenge_tooltip_dismiss',
      'journey_tooltip_challenges',
      'challenge_tooltip_start',
      'activities_weekly_tooltip',
      'skip_waiting_questionnaires',
    ];

    for (const flag of flagsToReset) {
      this.userFlags[flag] = false;
    }

    this.save();
  }

  /**
   * Syncs the user flag before setting it locally.
   *
   * @param flag: Flag to be set
   * @param value: Value of the flag
   */
  public syncAndSet(flag: UserFlagNames, value: boolean) {
    return new Promise((resolve, reject) => {
      this.userApiService
        .putFlag(flag, value)
        .then(() => {
          this.userFlags[flag] = value;
          this.save();
          resolve(true);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /* Saves flags locally */
  private save() {
    this.dataProvider.data.user.flags = this.userFlags;
    this.storageProvider.saveData();
  }

  /* Sync flag with backend */
  private sync(flagToSync: any) {
    if (flagToSync == null || flagToSync === {} || flagToSync === undefined) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(() => {});
    }

    const flag = Object.keys(flagToSync)[0];
    const value = flagToSync[flag];

    return this.userApiService.putFlag(flag, value);
  }

  private updateFlags() {
    if (this.dataProvider.data != null && this.dataProvider.data.user) {
      this.userFlags = this.dataProvider.data.user.flags;
    }

    if (this.userFlags == null || this.userFlags === undefined) {
      this.userFlags = {
        completed_onboarding: false,
        dismissed_timeline_journeys_tip: false,
        dismissed_timeline_articles_tip: false,
        dismissed_timeline_questionnaire_tip: false,
        timeline_completed_tab_tooltip: false,
        view_challenge_onboarding: false,
        view_profile_tab_tooltip: false,
        view_results_tab_tooptip: false,
        view_timeline_tab_tooltip: false,
        results_biomarker_list_tip: false,
        app_rating_dismissed: false,
        weekly_plan_tooltip: false,
        disable_analytics: false,
      };
    }
  }
}
