import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, timeout } from 'rxjs/operators';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { DataProviderService } from '../services/data-provider/data-handler.service';
import { environment as environmentDevelopment } from '../../../environments/environment.dev';
import { environment as environmentDefault } from '../../../environments/environment';
import { EventsService } from '../services/events/events.service';
import { Data } from '../../shared/interfaces/data-provider.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private maxWaiting = 45000;

  private impersonate = null;

  private refreshTokenInProgress = false;

  private refreshTokenSubject: Subject<any> = new Subject<any>();

  constructor(
    private http: HttpClient,
    private dataProvider: DataProviderService,
    private eventsService: EventsService
  ) {}

  getApiEndpoint() {
    return this.dataProvider.data.isDevelopmentEndpoint
      ? environmentDevelopment.api
      : environmentDefault.api;
  }

  del(url: string, body?: any): Observable<any> {
    const headers: HttpHeaders = this.createHeaders();

    return this.validateHeaders(headers).pipe(
      switchMap((validatedHeaders) =>
        this.http
          .delete(this.getApiEndpoint() + url, { headers: validatedHeaders, params: body })
          .pipe(
            timeout(this.maxWaiting),
            catchError((error: any) => this.handleError(error))
          )
      )
    );
  }

  post(url: string, body: Object): Observable<any> {
    const headers = this.createHeaders();

    return this.validateHeaders(headers).pipe(
      switchMap((validatedHeaders) =>
        this.http.post(this.getApiEndpoint() + url, body, { headers: validatedHeaders }).pipe(
          timeout(this.maxWaiting),
          catchError((error: any) => this.handleError(error))
        )
      )
    );
  }

  put(url: string, body: Object): Observable<any> {
    const headers = this.createHeaders();

    return this.validateHeaders(headers).pipe(
      switchMap((validatedHeaders) =>
        this.http
          .put(this.getApiEndpoint() + url, JSON.stringify(body), { headers: validatedHeaders })
          .pipe(
            timeout(this.maxWaiting),
            catchError((error: any) => this.handleError(error))
          )
      )
    );
  }

  get(url: string, params: any = undefined): Observable<any> {
    const headers = this.createHeaders();

    return this.validateHeaders(headers).pipe(
      switchMap((validatedHeaders) =>
        this.http.get(this.getApiEndpoint() + url, { headers: validatedHeaders, params }).pipe(
          timeout(this.maxWaiting),
          catchError((error: any) => this.handleError(error))
        )
      )
    );
  }

  getLocal(url: string): Observable<any> {
    const headers = this.createHeaders();

    return this.http.get(`http://localhost:8100${url}`, { headers }).pipe(
      timeout(this.maxWaiting),
      catchError((error: any) => this.handleError(error))
    );
  }

  createHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Accept', 'application/json');

    if (this.dataProvider.data) {
      if (
        this.dataProvider.data.authentication &&
        this.dataProvider.data.authentication.token_type &&
        this.dataProvider.data.authentication.access_token
      ) {
        headers = headers.append(
          'Authorization',
          `${this.dataProvider.data.authentication.token_type} ${this.dataProvider.data.authentication.access_token}`
        );
      }

      headers = headers.append(
        'Accept-Language',
        this.dataProvider.data.settings.defaultLang.locale
      );
    }

    if (this.impersonate != null) {
      headers = headers.append('Impersonate-User', this.impersonate);
    }

    return headers;
  }

  validateHeaders(headers: HttpHeaders): Observable<HttpHeaders> {
    const authData = this.dataProvider.data.authentication;

    if (headers.has('Authorization') && this.isTokenExpired(authData.access_token)) {
      if (this.refreshTokenInProgress) {
        return this.refreshTokenSubject.pipe(
          switchMap(() =>
            of(
              headers.set(
                'Authorization',
                `${authData.token_type} ${this.dataProvider.data.authentication.access_token}`
              )
            )
          )
        );
      }

      this.refreshTokenInProgress = true;
      this.refreshTokenSubject = new Subject<any>();

      return this.refreshAccessToken(authData.access_token, authData.refresh_token).pipe(
        map((response) => {
          this.dataProvider.data.authentication = {
            ...authData,
            access_token: response.access_token,
            refresh_token: response.refresh_token,
          };

          this.refreshTokenInProgress = false;
          this.refreshTokenSubject.next(response);
          this.refreshTokenSubject.complete();

          return headers.set('Authorization', `${authData.token_type} ${response.access_token}`);
        }),
        catchError((error) => {
          this.refreshTokenInProgress = false;
          console.error('Error refreshing access token', error);
          return of(headers);
        })
      );
    }

    return of(headers);
  }

  refreshAccessToken(
    accessToken: string,
    refreshToken: string
  ): Observable<Data['authentication']> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

    return this.http
      .post<Data['authentication']>(
        `${this.getApiEndpoint()}/app/auth/refresh`,
        {
          access_token: accessToken,
          refresh_token: refreshToken,
        },
        { headers }
      )
      .pipe(
        timeout(this.maxWaiting),
        catchError((error: any) => {
          if (error.status === 400) {
            this.eventsService.userLogoutSubject$.next();
            return throwError(error);
          }

          return this.handleError(error);
        })
      );
  }

  /**
   * Set impersonate User ID. Used to set request headers to impersonate another user.
   * @param wellabeId - UserID to be impersonated
   */
  public setImpersonateHeader(wellabeId: string) {
    this.impersonate = wellabeId;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status && error.status === 401) {
      this.eventsService.userLogoutSubject$.next();
    }

    if (!navigator.onLine) {
      return throwError('ERROR_NO_INTERNET');
    }
    if ((error as any).name === 'TimeoutError') {
      return throwError('ERROR_TIMEOUT');
    }
    return throwError(error);
  }

  private isTokenExpired(token: string): boolean {
    const decoded: JwtPayload = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp ? decoded.exp < currentTime : false;
  }
}
